section.new-form-section {
  position: fixed;
  top: 5.825em;
  width: 60.5em;
  left: 20em;
  padding: 3em;
  background-color: #EBF1F5;
  z-index: 101;
  max-height: 35em;
  overflow-y: scroll; }
  section.new-form-section h3 {
    margin-bottom: 2em;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2875em;
    font-weight: 600; }
    section.new-form-section h3.section-title {
      font-family: "Open Sans", sans-serif;
      font-size: 1.5875em;
      font-weight: 600; }
  section.new-form-section button {
    background-color: #2B54D0;
    margin-left: 1em;
    padding-left: 1em;
    padding-right: 1em; }
    section.new-form-section button.form-cancel {
      background-color: #3E485A; }
  section.new-form-section div.btns-container {
    margin-top: 1em;
    margin-bottom: 1em; }
  section.new-form-section div.bg {
    background-color: #e0e4ea;
    padding-bottom: 1em;
    padding-top: 1em; }
    section.new-form-section div.bg div.auto-complete-search {
      position: relative; }
      section.new-form-section div.bg div.auto-complete-search div.search-list {
        position: absolute;
        left: 5.9em;
        top: 2.5em;
        width: 13.8125em;
        padding-left: 1em;
        padding-right: 1em;
        z-index: 1; }
        section.new-form-section div.bg div.auto-complete-search div.search-list ul {
          max-height: 20.5em;
          padding: 0.5em;
          overflow-y: scroll;
          list-style-type: none;
          border: 1px solid #EBF1F5;
          background-color: #EBF1F5;
          box-sizing: border-box;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
          section.new-form-section div.bg div.auto-complete-search div.search-list ul li {
            display: block;
            width: 100%;
            background-color: transparent;
            color: #000000;
            transition: all 0.2s;
            text-decoration: none;
            cursor: pointer; }
            section.new-form-section div.bg div.auto-complete-search div.search-list ul li:hover {
              background-color: #2B54D0;
              color: #FFFFFF; }
            section.new-form-section div.bg div.auto-complete-search div.search-list ul li.list-no-result {
              text-align: center;
              text-transform: capitalize;
              color: gray; }
