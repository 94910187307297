div.application-item {
  position: relative;
  background-color: #EBF1F5;
  margin-bottom: 0.5em;
  padding: 1em 0em;
  border-radius: 3px; }
  div.application-item p {
    display: inline-block;
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
    div.application-item p span {
      color: #85aac2; }
  div.application-item a.view-info {
    margin-right: 1em;
    color: #3E485A;
    font-size: 1.3758em; }
  div.application-item a.applicant-info {
    margin-right: 0.5em;
    font-size: 1.1758em; }
  div.application-item ul.adminList {
    display: none;
    position: absolute;
    right: -1.3em;
    top: 3em;
    min-width: 13.6em;
    max-height: 20.5em;
    padding: 0.5em;
    overflow-y: scroll;
    list-style-type: none;
    border: 1px solid #EBF1F5;
    background-color: #EBF1F5;
    box-sizing: border-box;
    list-style-type: none;
    z-index: 100;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    div.application-item ul.adminList li {
      display: block;
      width: 100%;
      background-color: transparent;
      color: #000000;
      transition: all 0.2s;
      text-decoration: none;
      cursor: pointer; }
      div.application-item ul.adminList li:hover {
        background-color: #2B54D0;
        color: #FFFFFF; }
      div.application-item ul.adminList li.close {
        text-align: right; }
        div.application-item ul.adminList li.close i {
          font-size: 0.8em;
          color: #4A4A4A; }
        div.application-item ul.adminList li.close:hover {
          background-color: #EBF1F5;
          color: #4A4A4A; }
  div.application-item button:hover ul.adminList {
    display: block; }
  div.application-item button i {
    font-size: 1.0624em;
    color: #9DA2AB; }
  div.application-item button.active i {
    color: #F44336; }
