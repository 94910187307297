section.request-docs-modal {
  position: fixed;
  top: 5.825em;
  width: 50.5em;
  left: 20em;
  padding: 3em;
  background-color: #EBF1F5;
  z-index: 101;
  max-height: 35em;
  overflow-y: scroll; }
  section.request-docs-modal h3 {
    margin-bottom: 1em;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2875em;
    font-weight: 600; }
    section.request-docs-modal h3.section-title {
      font-family: "Open Sans", sans-serif;
      font-size: 1.5875em;
      font-weight: 600; }
  section.request-docs-modal textarea {
    display: block;
    width: 100%;
    height: 15em;
    margin-bottom: 2.5rem;
    padding: 1em;
    border: 1px solid #d3d3d3;
    border-radius: .3em;
    resize: none;
    outline: none; }
  section.request-docs-modal button.close-modal {
    position: absolute;
    right: 0;
    top: -1em;
    font-size: 1.5em !important; }
    section.request-docs-modal button.close-modal i {
      color: #3E485A; }
  section.request-docs-modal button.send-request {
    float: right;
    background-color: #2B54D0;
    padding-left: 1em;
    padding-right: 1em; }
    section.request-docs-modal button.send-request i {
      margin-right: 0.5em; }
