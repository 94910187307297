div.doc-row a.doc-button {
  text-decoration: none;
  color: #000000;
  display: inline-block;
  cursor: default;
  font-family: "Open Sans", sans-serif;
  font-size: 1.0624em;
  font-weight: 400; }
  div.doc-row a.doc-button i {
    margin-right: 0.5em;
    color: #9DA2AB; }
  div.doc-row a.doc-button.available {
    cursor: pointer; }
    div.doc-row a.doc-button.available i {
      color: #2B54D0; }

div.doc-row button {
  background-color: #3E485A;
  font-size: 0.845em !important;
  margin-right: 0.5em;
  padding-left: 0.7em;
  padding-right: 0.7em; }
  div.doc-row button i {
    margin-right: 0.3em; }

div.doc-buttons {
  margin-top: 1.5em;
  margin-bottom: 1em; }
  div.doc-buttons button {
    padding-left: 0.7em;
    padding-right: 0.7em; }
    div.doc-buttons button.doc-save {
      background-color: #2B54D0;
      margin-right: 0.5em; }
    div.doc-buttons button.doc-request {
      background-color: #3E485A; }
      div.doc-buttons button.doc-request i {
        margin-right: 0.5em; }
