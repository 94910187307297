section.lessons-table-heading {
  margin-bottom: 1.25em; }

section.lessons-table-rows {
  margin-left: unset;
  margin-right: unset; }

div.lessons-info-wrap {
  padding-top: 1.25em;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; }
  div.lessons-info-wrap div.page-tool-heading h3 {
    font-size: 1.2875em; }
