h3.form-group-title {
  margin-bottom: 1em !important;
  margin-top: 1.5em; }

div.input-wrap {
  margin-bottom: 1em; }

div.form-group-items {
  margin-top: 1.5em;
  background-color: #e0e4ea;
  padding-bottom: 1em; }
