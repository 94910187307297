label.form-label {
  margin-left: 1.25em;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  font-size: 1.0624em;
  font-weight: 600; }

div.form-text-wrap,
div.form-radio-wrap,
div.form-select-wrap {
  height: 2.5em;
  line-height: 2.5em; }

div.form-text-wrap,
div.form-select-wrap {
  border: 1px solid #c2c9d5;
  border-radius: 1.25em; }

div.form-text-wrap input {
  margin-left: 1.2em;
  margin-right: 0.3em;
  border: transparent;
  background-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-size: 1.0624em;
  font-weight: 400; }
  div.form-text-wrap input:focus {
    outline: none; }

div.form-text-wrap ::placeholder {
  color: rgba(0, 0, 0, 0.125); }

div.form-text-wrap i {
  color: #b5c2e8;
  font-size: 1.2em;
  cursor: pointer; }

div.form-radio-wrap div {
  display: inline-block;
  width: 5.625em; }
  div.form-radio-wrap div input {
    margin-right: 0.5em; }
  div.form-radio-wrap div label {
    text-transform: capitalize; }

div.form-select-wrap select {
  margin-left: 0.3em;
  border: transparent;
  background-color: transparent; }
  div.form-select-wrap select:focus {
    outline: none; }

div.invalid-input {
  border-color: #F44336; }
  div.invalid-input i {
    color: #f77066; }
