div.slides-info-wrap {
  margin-bottom: 0.5em;
  padding-top: 1.25em;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; }
  div.slides-info-wrap div.page-tool-heading h3 {
    font-size: 1.2875em; }
  div.slides-info-wrap section.slides-table-heading {
    margin-bottom: 1.25em; }
    div.slides-info-wrap section.slides-table-heading h6 {
      text-transform: capitalize;
      padding-left: 0.7375em;
      color: #9DA2AB;
      font-family: "Open Sans", sans-serif;
      font-size: 1.0624em;
      font-weight: 600; }
      div.slides-info-wrap section.slides-table-heading h6.right-aligned {
        text-align: right;
        margin-right: 0.5em; }
    div.slides-info-wrap section.slides-table-heading a {
      display: inline-block;
      color: #CDCFD1;
      font-size: 1.3758em; }
      div.slides-info-wrap section.slides-table-heading a.saveActive {
        color: #2B54D0; }
  div.slides-info-wrap section.slides-json-text textarea {
    width: 100%;
    height: 30em;
    border: 1px solid #9DA2AB;
    border-radius: 3px; }
