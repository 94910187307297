div.stats-tile {
  margin-top: 2em; }
  div.stats-tile div.tile-heading {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
    div.stats-tile div.tile-heading h4 {
      margin-bottom: 0;
      padding-bottom: 0.1em;
      background-color: #2B54D0;
      text-transform: capitalize;
      line-height: 2.2;
      color: #FFFFFF;
      padding-left: 0.5em;
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 400; }
      div.stats-tile div.tile-heading h4 i {
        margin-right: 0.5em; }
  div.stats-tile div.tile-content {
    min-height: 8.25em;
    text-align: center;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
    div.stats-tile div.tile-content i {
      margin-right: 0.2em;
      font-size: 0.8375em; }
      div.stats-tile div.tile-content i.fa-long-arrow-up {
        color: #7ED321; }
      div.stats-tile div.tile-content i.fa-long-arrow-down {
        color: #F44336; }
    div.stats-tile div.tile-content p {
      width: 80%;
      padding-top: 0.3em;
      border-top: 1px solid #ccd1d8;
      line-height: initial;
      margin-bottom: 0;
      text-transform: capitalize; }
    div.stats-tile div.tile-content span.view-tile-stats {
      padding-bottom: 0.3em;
      font-family: "Open Sans", sans-serif;
      font-size: 0.845em;
      font-weight: 400; }

div.inactive div.tile-content {
  background-color: #EBF1F5; }

div.inactive:hover div.tile-heading {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

div.inactive:hover div.tile-content {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

div.active div.tile-heading {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

div.active div.tile-content {
  background-color: #2B54D0;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }
  div.active div.tile-content h3 {
    color: #FFFFFF; }
  div.active div.tile-content p, div.active div.tile-content span.view-tile-stats {
    color: #FFFFFF; }
