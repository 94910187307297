div.info-row {
  background-color: #EBF1F5;
  margin-bottom: 1em;
  padding-top: 1.0625em;
  padding-bottom: 1.0625em;
  border-radius: 3px; }
  div.info-row div.info-row-item a {
    display: inline-block;
    color: #CDCFD1;
    font-size: 1.3758em; }
    div.info-row div.info-row-item a.view-info {
      color: #4A4A4A;
      margin-left: 1em; }
    div.info-row div.info-row-item a.saveActive {
      color: #2B54D0; }
    div.info-row div.info-row-item a.delete:hover {
      color: #F44336; }
  div.info-row div.info-row-item input {
    width: 100%;
    padding: 0.3em;
    border: none;
    background-color: transparent;
    outline: none;
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
  div.info-row div.info-row-item ::placeholder {
    color: #4A4A4A; }
  div.info-row div.info-row-item p {
    text-transform: capitalize;
    margin: 0;
    color: #9DA2AB;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
  div.info-row div.info-row-item span.line-separator {
    display: inline-block;
    width: 1px;
    height: 1em;
    background-color: #CDCFD1;
    margin-left: 0.5em;
    margin-right: 0.5em; }
  div.info-row div.info-row-item:first-child {
    border-right: 1px solid #CDCFD1; }
  div.info-row div.info-row-item:last-child {
    text-align: right; }
  div.info-row.expanded {
    background-color: #2B54D0;
    margin-bottom: 0; }
    div.info-row.expanded div.info-row-item a,
    div.info-row.expanded div.info-row-item input,
    div.info-row.expanded div.info-row-item p,
    div.info-row.expanded div.info-row-item span.line-separator,
    div.info-row.expanded div.info-row-item ::placeholder {
      color: #FFFFFF; }
    div.info-row.expanded div.info-row-item a:hover.delete {
      color: #F44336; }

div[draggable="true"] div.info-row-item a.info-row-dragger {
  color: #4A4A4A; }
