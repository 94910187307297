section.report-edits-header,
section.report-title,
section.report-page-navigation,
div.loading-report {
  margin-top: 2em; }
  section.report-edits-header h3,
  section.report-title h3,
  section.report-page-navigation h3,
  div.loading-report h3 {
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5875em;
    font-weight: 600; }

section.report-edits-header h3 {
  margin-top: 1em;
  margin-bottom: 1em; }

section.report-edits-header p {
  font-family: "Open Sans", sans-serif;
  font-size: 1.0624em;
  font-weight: 400; }

section.report-title a {
  float: right;
  text-transform: capitalize; }
  section.report-title a i {
    padding: 0.55em;
    border-radius: 50%;
    background-color: #263A78;
    color: #FFFFFF;
    margin-right: 0.5em; }
  section.report-title a:hover {
    text-decoration: none; }

section.report-content {
  border: 1px solid #ccd1d8; }

section.report-page-navigation {
  margin-bottom: 4em; }
  section.report-page-navigation button {
    padding-left: 1.5em;
    padding-right: 1.5em;
    background-color: #263A78; }
    section.report-page-navigation button i.fa-long-arrow-left {
      margin-left: 0em !important;
      margin-right: 1em; }
      section.report-page-navigation button i.fa-long-arrow-left.fa-long-arrow-left {
        margin-left: 1em; }

@media print {
  section.view-users-filter,
  section.report-content,
  section.report-edits-header,
  section.report-title,
  section.report-page-navigation {
    display: none !important; } }
