div.profile-nav-container {
  position: absolute;
  top: 3em;
  left: -4em;
  background-color: #FFFFFF;
  text-align: left;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  div.profile-nav-container ul {
    position: relative;
    width: 13em;
    margin-bottom: 0;
    padding-left: 0;
    border: 1px solid #EBF1F5;
    border-radius: 3px;
    list-style-type: none; }
    div.profile-nav-container ul li {
      display: block;
      padding: 0.5em 1em;
      transition: all 0.125s ease-in; }
      div.profile-nav-container ul li a {
        display: block;
        text-decoration: none;
        color: #9DA2AB;
        transition: all 0.125s ease-in; }
        div.profile-nav-container ul li a i {
          margin-right: 0.5em;
          transition: all 0.125s ease-in; }
      div.profile-nav-container ul li:last-child {
        padding-top: 0.5em;
        border-top: 1px solid #EBF1F5;
        background-color: #EBF1F5; }
      div.profile-nav-container ul li:hover a {
        color: #3E485A; }
        div.profile-nav-container ul li:hover a i {
          color: #9DA2AB; }
      div.profile-nav-container ul li:hover:last-child {
        background-color: #2B54D0; }
        div.profile-nav-container ul li:hover:last-child a {
          color: #FFFFFF; }
          div.profile-nav-container ul li:hover:last-child a i {
            color: #FFFFFF; }
