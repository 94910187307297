section.form-modal {
  position: fixed;
  top: 5.825em;
  width: 35em;
  padding: 1em;
  background-color: #EBF1F5;
  text-align: center;
  z-index: 101; }
  section.form-modal button {
    position: absolute;
    right: 1.4em;
    top: 0;
    width: 1em;
    color: #9DA2AB; }
  section.form-modal p {
    font-family: "Open Sans", sans-serif;
    font-size: 1.2875em;
    font-weight: 600; }
    section.form-modal p i {
      margin-right: 0.5em;
      font-size: 2em;
      vertical-align: middle; }
      section.form-modal p i.fa-times-circle {
        color: #F44336; }
      section.form-modal p i.fa-check-circle {
        color: #4CAF50; }
