div.message-date {
  background-color: #EBF1F5; }
  div.message-date p {
    margin-bottom: 0;
    padding: 0.5em 1em;
    color: #FFFFFF;
    background-color: #6DD400;
    border-radius: 4em;
    text-transform: capitalize;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 0.845em;
    font-weight: 600; }
