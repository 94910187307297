div.total-count-card {
  padding: 0.5em;
  text-align: center;
  background-color: #2B54D0;
  margin-bottom: 1em; }
  div.total-count-card p {
    margin-bottom: 0;
    color: #FFFFFF; }
    div.total-count-card p.total-count {
      font-family: "Open Sans", sans-serif;
      font-size: 1.0624em;
      font-weight: 600; }
    div.total-count-card p.total-title {
      text-transform: capitalize;
      font-family: "Open Sans", sans-serif;
      font-size: 0.845em;
      font-weight: 400; }
