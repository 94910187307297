div.group-heading h5 {
  color: #9DA2AB;
  font-family: "Open Sans", sans-serif;
  font-size: 1.0624em;
  font-weight: 600; }

div.group-heading div.group-title-line {
  margin-left: -3.5em; }

div.groups {
  white-space: nowrap;
  overflow-x: scroll; }

div.group-scroll-btns button {
  color: #3E485A; }
  div.group-scroll-btns button i {
    font-size: 1.5em; }
  div.group-scroll-btns button.left-scroll {
    margin-right: 1em; }
