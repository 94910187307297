div.insights-page div.page-title {
  margin-bottom: 1em; }

div.insights-page div.insights-block {
  margin-left: -1em;
  margin-right: -1em;
  margin-bottom: 1em; }
  div.insights-page div.insights-block span {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 1em;
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2875em;
    font-weight: 600; }
  div.insights-page div.insights-block div.insight01 {
    background-color: #6DD400;
    padding-bottom: 1em;
    padding-right: 1em; }
  div.insights-page div.insights-block div.insight02 {
    background-color: #3F8D90;
    padding-bottom: 1em;
    padding-right: 1em; }
  div.insights-page div.insights-block div.insight03 {
    background-color: #32C5FF;
    padding-bottom: 1em;
    padding-right: 1em; }
  div.insights-page div.insights-block div.insight04 {
    background-color: #F7B500;
    padding-bottom: 1em;
    padding-right: 1em; }
