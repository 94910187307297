div.bar-chart h4 {
  color: #3E485A;
  padding-left: 0.5em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-bottom: 1.5em;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2273em;
  font-weight: 600; }

div.bar-chart svg rect:first-child {
  fill: #EBF1F5; }

div.bar-chart div.bar-chart-graph {
  margin-bottom: 2em; }
