div.chart-no-info {
  text-align: center;
  margin-top: 4em; }
  div.chart-no-info i {
    margin-bottom: 0.3em;
    font-size: 2.5375em;
    color: #f77066; }
  div.chart-no-info p {
    color: #9DA2AB;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2875em;
    font-weight: 600; }
