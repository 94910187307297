div.page-tool-heading h3 {
  padding-left: 0; }

section.messenger-container div.messenger-user-info {
  background-color: #2B54D0;
  padding: 0.3em 0.5em; }
  section.messenger-container div.messenger-user-info div.pro-pic-wrap {
    display: inline-block;
    background-color: #EBF1F5;
    border-radius: 50%;
    width: 4em;
    height: 4em;
    overflow: hidden;
    vertical-align: middle;
    margin-right: 1em; }
    section.messenger-container div.messenger-user-info div.pro-pic-wrap img {
      width: 100%;
      margin-right: 0.5em; }
  section.messenger-container div.messenger-user-info h4 {
    display: inline-block;
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2875em;
    font-weight: 600; }

section.messenger-container div.messenger-search {
  padding-top: 3.4em;
  padding-bottom: 3.5em;
  background-color: #EBF1F5;
  text-align: center; }
  section.messenger-container div.messenger-search div.search-field {
    border: 1px solid #4A4A4A;
    border-radius: 5em;
    display: inline-block;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    background-color: #FFFFFF; }
    section.messenger-container div.messenger-search div.search-field input {
      background-color: transparent;
      border-color: transparent;
      width: 15em; }
      section.messenger-container div.messenger-search div.search-field input:focus {
        outline: none; }
    section.messenger-container div.messenger-search div.search-field a {
      color: #FFFFFF; }
      section.messenger-container div.messenger-search div.search-field a i {
        background-color: #263A78;
        padding: 0.4em;
        border-radius: 50%; }

section.messenger-container div.user-lists {
  background-color: #EBF1F5; }

section.messenger-container div.messenger-settings {
  background-color: #263A78;
  padding: 1.45em 0.2em; }
  section.messenger-container div.messenger-settings a.cog {
    float: right;
    margin-right: 1em;
    color: white; }
    section.messenger-container div.messenger-settings a.cog i {
      font-size: 1.5em; }

section.messenger-container div.dashboard {
  background-color: #EBF1F5;
  white-space: nowrap;
  overflow-x: scroll; }

section.messenger-container div.conversation-container {
  background-color: #E9EBEE;
  height: 40em;
  padding: 1em 0.3em;
  overflow-y: scroll; }
