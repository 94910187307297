div.application-card div.admin-name-wrap {
  margin-bottom: 0;
  padding: 0.5em 1em;
  background-color: #2B54D0; }
  div.application-card div.admin-name-wrap div.form-select-wrap {
    border-color: transparent !important; }
    div.application-card div.admin-name-wrap div.form-select-wrap select {
      width: 100%;
      color: #FFFFFF;
      background-color: #2B54D0;
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 600; }
      div.application-card div.admin-name-wrap div.form-select-wrap select[disabled] {
        -webkit-appearance: none;
        -moz-appearance: none; }

div.application-card div.status-list-contanier {
  position: relative;
  min-height: 9em;
  background-color: #EBF1F5; }
  div.application-card div.status-list-contanier ul {
    padding: 0.5em;
    list-style-type: none; }
    div.application-card div.status-list-contanier ul li {
      display: block;
      margin-bottom: 0.2em;
      color: #3E485A;
      transition: all 0.125s ease-in; }
      div.application-card div.status-list-contanier ul li a {
        display: block;
        text-decoration: none;
        color: #3E485A; }
        div.application-card div.status-list-contanier ul li a span {
          float: right; }
      div.application-card div.status-list-contanier ul li:hover {
        background-color: #5375db;
        color: #FFFFFF; }
        div.application-card div.status-list-contanier ul li:hover a {
          color: #FFFFFF; }
  div.application-card div.status-list-contanier p.no-applications {
    text-align: center;
    line-height: 9em; }
