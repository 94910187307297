div.allocation-item p {
  margin-bottom: 0.3em;
  text-align: center; }
  div.allocation-item p.institution-name {
    text-transform: uppercase;
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
  div.allocation-item p.progress-precent {
    margin-top: 0.3em;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    font-weight: 300; }

div.allocation-item div.progress-bar-bg {
  height: 1em;
  background-color: #EBF1F5;
  border-radius: 0.5em;
  overflow: hidden; }
  div.allocation-item div.progress-bar-bg div.progress-bar {
    height: 1em; }
    div.allocation-item div.progress-bar-bg div.progress-bar.red {
      background-color: #E02020; }
    div.allocation-item div.progress-bar-bg div.progress-bar.orange {
      background-color: #F7B500; }
    div.allocation-item div.progress-bar-bg div.progress-bar.blue {
      background-color: #32C5FF; }
    div.allocation-item div.progress-bar-bg div.progress-bar.green {
      background-color: #6DD400; }
