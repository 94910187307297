div.application-info div.login-details {
  padding: 2em;
  margin-bottom: 3em;
  background-color: #EBF1F5; }
  div.application-info div.login-details button.save-login-info {
    position: relative;
    bottom: -1.6em;
    padding-left: 1em;
    padding-right: 1em;
    background-color: #2B54D0; }
    div.application-info div.login-details button.save-login-info i {
      margin-right: 0.5em; }
