div.high-level-page div.schools-total p, div.high-level-page div.total p {
  color: #6DD400;
  border: 2px solid #6DD400;
  border-radius: 50%;
  padding: 1em;
  text-align: center;
  transform: scale(0.7);
  font-family: "Open Sans", sans-serif;
  font-size: 5em;
  font-weight: 700; }

div.high-level-page div.schools-total.total p, div.high-level-page div.total.total p {
  color: #32C5FF; }

div.high-level-page div.schools-total.blue p, div.high-level-page div.total.blue p {
  border: 12px solid #32C5FF; }

div.high-level-page i.text-icon {
  display: inline-block;
  padding: 0.9em;
  border-radius: 50%;
  color: #FFFFFF;
  margin-top: 0.5em; }
  div.high-level-page i.text-icon.green {
    background-color: #6DD400; }
  div.high-level-page i.text-icon.blue {
    background-color: #32C5FF; }
