div.message-box {
  position: fixed;
  top: 5.825em;
  width: 50em;
  padding: 2em;
  background-color: #EBF1F5;
  z-index: 101; }
  div.message-box button.modal-close {
    position: absolute;
    right: 0;
    top: -1em;
    font-size: 1.5em !important; }
    div.message-box button.modal-close i {
      color: #3E485A; }
  div.message-box h3 {
    margin-bottom: 0.5625em;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5875em;
    font-weight: 600; }
  div.message-box p {
    color: #3E485A;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
    div.message-box p span {
      font-weight: 600;
      margin-left: 0.3em; }
  div.message-box div.time-filter {
    text-align: right;
    margin-bottom: 2em; }
    div.message-box div.time-filter label.filter-label {
      margin-right: 0.5em; }
    div.message-box div.time-filter div.time-filter-wrap {
      display: inline-block; }
      div.message-box div.time-filter div.time-filter-wrap div.react-datetime-picker__calendar div.react-calendar {
        border: 1px solid #c2c9d5;
        background-color: #EBF1F5;
        border-radius: 3px; }
  div.message-box textarea {
    display: block;
    width: 100%;
    height: 20em;
    margin-bottom: 2.5rem;
    padding: 1em;
    border: lightgray 1px solid;
    border-radius: 0.3em;
    resize: none;
    outline: none; }
  div.message-box button.send-message {
    background-color: #2B54D0;
    width: 10.625em;
    height: 2em;
    float: right; }
    div.message-box button.send-message i {
      margin-right: 0.3em; }
