p.group-item-title {
  position: relative;
  margin-top: 2em;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  font-size: 1.0624em;
  font-weight: 600;
  color: #9DA2AB;
  margin-left: 2.4em; }
  p.group-item-title:before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -2em;
    width: 1.5em;
    background-color: #9DA2AB;
    content: "";
    height: 2px; }

nav.toolbar-group-item {
  margin-left: 2.4em; }
  nav.toolbar-group-item a {
    font-size: 0.95em !important; }
