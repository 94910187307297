form#student-info button.toggle-form-section {
  background-color: #3E485A;
  padding: 0 0.6em; }

form#student-info h3 {
  display: inline-block;
  margin-bottom: 0.5625em;
  padding-left: 0.5em;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5875em;
  font-weight: 600; }

form#student-info div.form-section {
  margin-bottom: 2.5em; }
  form#student-info div.form-section div.form-inputs {
    padding: 1.25em;
    background-color: #EBF1F5; }
    form#student-info div.form-section div.form-inputs div.auto-complete-search {
      position: relative; }
      form#student-info div.form-section div.form-inputs div.auto-complete-search div.search-list {
        position: absolute;
        left: 0;
        top: 4.6em;
        width: 16.4125em;
        padding-left: 1em;
        padding-right: 1em;
        z-index: 1; }
        form#student-info div.form-section div.form-inputs div.auto-complete-search div.search-list ul {
          max-height: 20.5em;
          padding: 0.5em;
          overflow-y: scroll;
          list-style-type: none;
          border: 1px solid #EBF1F5;
          background-color: #EBF1F5;
          box-sizing: border-box;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
          form#student-info div.form-section div.form-inputs div.auto-complete-search div.search-list ul li {
            display: block;
            width: 100%;
            background-color: transparent;
            color: #000000;
            transition: all 0.2s;
            text-decoration: none;
            cursor: pointer; }
            form#student-info div.form-section div.form-inputs div.auto-complete-search div.search-list ul li:hover {
              background-color: #2B54D0;
              color: #FFFFFF; }
            form#student-info div.form-section div.form-inputs div.auto-complete-search div.search-list ul li.list-no-result {
              text-align: center;
              text-transform: capitalize;
              color: gray; }

form#student-info div.form-buttons {
  margin-bottom: 5em; }
  form#student-info div.form-buttons button {
    width: 8.525em;
    height: 2em;
    border-radius: 1em;
    transform: scale(1);
    transition: all 0.2s ease-in;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    form#student-info div.form-buttons button.form-cancel {
      background-color: #3E485A; }
      form#student-info div.form-buttons button.form-cancel:hover {
        color: #3E485A; }
    form#student-info div.form-buttons button.form-add-student {
      background-color: #2B54D0; }
      form#student-info div.form-buttons button.form-add-student:hover {
        color: #2B54D0; }
        form#student-info div.form-buttons button.form-add-student:hover div.spinner div {
          background-color: #2B54D0; }
    form#student-info div.form-buttons button:hover {
      background-color: #FFFFFF;
      transform: scale(1.01);
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }
    form#student-info div.form-buttons button:focus {
      outline: none; }
