header {
  position: relative;
  text-transform: capitalize;
  z-index: 2; }
  header div.row {
    height: 6.625em; }
  header.login, header.admin-tools {
    margin-bottom: 4.0375em; }
    header.login h1, header.admin-tools h1 {
      margin-bottom: 0;
      color: #3E485A;
      font-family: "Open Sans", sans-serif;
      font-size: 2.5375em;
      font-weight: 700; }
  header.add-user, header.modules, header.users, header.stats, header.feeds, header.applications, header.expo-report, header.messenger {
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 6.25em;
    padding-right: 6.25em;
    background-color: #2B54D0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    header.add-user div.row, header.modules div.row, header.users div.row, header.stats div.row, header.feeds div.row, header.applications div.row, header.expo-report div.row, header.messenger div.row {
      height: 5.625em; }
    header.add-user a.admin-tools-toggle, header.modules a.admin-tools-toggle, header.users a.admin-tools-toggle, header.stats a.admin-tools-toggle, header.feeds a.admin-tools-toggle, header.applications a.admin-tools-toggle, header.expo-report a.admin-tools-toggle, header.messenger a.admin-tools-toggle {
      font-size: 1.7em;
      color: #EBF1F5; }
    header.add-user h1, header.modules h1, header.users h1, header.stats h1, header.feeds h1, header.applications h1, header.expo-report h1, header.messenger h1 {
      margin-bottom: 0;
      color: #FFFFFF;
      text-align: right;
      font-family: "Open Sans", sans-serif;
      font-size: 1.7375em;
      font-weight: 700; }

@media print {
  header {
    display: none !important; } }
