div.table-titles {
  border-bottom: 1px solid #EBF1F5;
  margin-bottom: 1em; }
  div.table-titles h5 {
    text-transform: capitalize;
    color: #9DA2AB;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 600; }
    div.table-titles h5.right-aligned {
      text-align: right;
      margin-right: .5em; }
