p.post-text {
  font-family: "Open Sans", sans-serif;
  font-size: 0.95em;
  font-weight: 400; }

img.post-image {
  border-radius: 6px; }

a.post-link {
  text-decoration: none;
  color: #3E485A; }
  a.post-link:hover {
    text-decoration: none;
    color: #3E485A; }
