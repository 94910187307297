div.status-card {
  margin: 0.5em 1em;
  padding: 0.1em;
  height: 8.5em;
  background-color: #FFFFFF;
  border: 1px solid #9DA2AB;
  border-radius: 3px; }
  div.status-card div.status {
    display: inline-block;
    width: 1em;
    height: 100%; }
  div.status-card div.status-info {
    display: inline-block;
    padding: 0.5em 1em;
    vertical-align: top; }
    div.status-card div.status-info p {
      margin-bottom: 0.5em; }
    div.status-card div.status-info p.institution-name {
      color: #2B54D0;
      font-family: "Open Sans", sans-serif;
      font-size: 1.0624em;
      font-weight: 600; }
    div.status-card div.status-info i {
      margin-right: 0.5em; }
      div.status-card div.status-info i.blue {
        color: #32C5FF; }
      div.status-card div.status-info i.red {
        color: #E02020; }
      div.status-card div.status-info i.green {
        color: #6DD400; }
      div.status-card div.status-info i.orange {
        color: #F7B500; }
    div.status-card div.status-info p.info {
      color: #9DA2AB;
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 600; }
    div.status-card div.status-info div.status-info-wrap p {
      display: inline-block; }
    div.status-card div.status-info div.status-info-wrap p.status-title {
      color: #4A4A4A;
      font-family: "Open Sans", sans-serif;
      font-size: 0.845em;
      font-weight: 600; }
    div.status-card div.status-info div.status-info-wrap p.status-text {
      display: block;
      width: 15em;
      color: #E9EBEE;
      white-space: pre-wrap; }
  div.status-card div.blue {
    background-color: #32C5FF; }
  div.status-card div.red {
    background-color: #E02020; }
  div.status-card div.green {
    background-color: #6DD400; }
  div.status-card div.orange {
    background-color: #F7B500; }
