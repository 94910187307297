section.dashboard {
  margin-top: 2em;
  margin-bottom: 4em;
  background-color: #EBF1F5; }
  section.dashboard div.dashboard-heading {
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: #2B54D0;
    box-sizing: border-box; }
    section.dashboard div.dashboard-heading h5 {
      margin-bottom: 0;
      color: #FFFFFF;
      text-transform: capitalize;
      font-family: "Open Sans", sans-serif;
      font-size: 1.0624em;
      font-weight: 600; }
      section.dashboard div.dashboard-heading h5.right-align {
        text-align: right; }
  section.dashboard div.group {
    margin-top: 1.25em;
    padding-left: 1em;
    padding-right: 1em; }
