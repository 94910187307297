div.group-item {
  margin-right: 1em;
  margin-bottom: 1em;
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  div.group-item div.group-item-heading {
    background-color: #2B54D0;
    padding-top: 1em;
    padding-bottom: 1em; }
    div.group-item div.group-item-heading h5, div.group-item div.group-item-heading h6 {
      margin-bottom: 0;
      color: #FFFFFF; }
    div.group-item div.group-item-heading h5 {
      text-transform: uppercase;
      font-family: "Open Sans", sans-serif;
      font-size: 1.0624em;
      font-weight: 600; }
    div.group-item div.group-item-heading h6 {
      text-transform: capitalize;
      text-align: right;
      font-family: "Open Sans", sans-serif;
      font-size: 1.0624em;
      font-weight: 400; }
  div.group-item div.group-item-content {
    padding-top: 2.5em;
    padding-bottom: 2em;
    background-color: #FFFFFF;
    text-align: center; }
    div.group-item div.group-item-content h3, div.group-item div.group-item-content p {
      margin-bottom: 0; }
    div.group-item div.group-item-content p {
      margin-bottom: 1em;
      text-transform: capitalize; }
    div.group-item div.group-item-content span.line {
      display: block;
      width: 50%;
      margin-left: 50%;
      margin-top: 0.3em;
      margin-bottom: 0.3em;
      transform: translateX(-50%);
      border: 1px solid #EBF1F5; }
