a.toolbarItem {
  display: block;
  margin-bottom: 0.95em;
  text-transform: capitalize;
  text-decoration: none;
  color: #9DA2AB;
  transition: all 0.052s ease-in;
  font-family: "Open Sans", sans-serif;
  font-size: 1.0624em;
  font-weight: 600; }
  a.toolbarItem:hover {
    color: #3E485A;
    text-decoration: none; }
    a.toolbarItem:hover i {
      color: #9DA2AB; }
  a.toolbarItem i {
    display: inline-block;
    width: 1.425em;
    height: 1.425em;
    line-height: 1.425em;
    margin-right: 0.5em;
    font-size: 1.25em;
    text-align: center;
    color: #9DA2AB; }

a.active {
  color: #3E485A; }
