div.applications-list div.side-borders {
  border-left: 1px solid #EBF1F5;
  border-right: 1px solid #EBF1F5; }

div.applications-list div.learner-info {
  margin-top: 2em;
  margin-bottom: 3em;
  border-bottom: 1px solid #EBF1F5; }
  div.applications-list div.learner-info h4 {
    color: #9DA2AB;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
  div.applications-list div.learner-info button {
    background-color: #2B54D0;
    padding: 0em 1em;
    margin-right: 1em; }
    div.applications-list div.learner-info button i {
      margin-right: 0.5em; }
    div.applications-list div.learner-info button.submit {
      float: right; }
  div.applications-list div.learner-info ul.adminList {
    display: none;
    position: absolute;
    right: 0.4em;
    top: 4em;
    max-height: 20.5em;
    padding: 0.5em;
    overflow-y: scroll;
    list-style-type: none;
    border: 1px solid #EBF1F5;
    background-color: #EBF1F5;
    box-sizing: border-box;
    list-style-type: none;
    z-index: 100;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    div.applications-list div.learner-info ul.adminList li {
      display: block;
      width: 100%;
      background-color: transparent;
      color: #000000;
      transition: all 0.2s;
      text-decoration: none;
      cursor: pointer; }
      div.applications-list div.learner-info ul.adminList li:hover {
        background-color: #2B54D0;
        color: #FFFFFF; }

div.applications-list button.add-new-institution,
div.applications-list button.reset {
  padding-left: 1em;
  padding-right: 1em;
  float: right; }
  div.applications-list button.add-new-institution.add-new-institution,
  div.applications-list button.reset.add-new-institution {
    background-color: #2B54D0; }
  div.applications-list button.add-new-institution.reset,
  div.applications-list button.reset.reset {
    background-color: #F44336; }
  div.applications-list button.add-new-institution i,
  div.applications-list button.reset i {
    margin-right: 0.5em; }
