div.subject-item {
  margin-bottom: 0.5em;
  background-color: #dbdee3; }
  div.subject-item div.subject-name p {
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    font-weight: 400; }
    div.subject-item div.subject-name p i {
      margin-right: 0.5em;
      font-size: 1.2em; }
      div.subject-item div.subject-name p i.green {
        color: #4CAF50; }
      div.subject-item div.subject-name p i.red {
        color: #F44336; }
  div.subject-item div.subject-marks div.mark-item {
    padding-top: 0.5em;
    padding-bottom: 0.5em; }
    div.subject-item div.subject-marks div.mark-item p {
      float: left;
      margin-bottom: 0;
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 400; }
    div.subject-item div.subject-marks div.mark-item span {
      float: right;
      background-color: #FFFFFF;
      border-radius: 50%;
      width: 3em;
      height: 3em;
      line-height: 3em;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-size: 0.845em;
      font-weight: 400; }
  div.subject-item div.subject-marks div.my-mark {
    background-color: #00fee0; }
  div.subject-item div.subject-marks div.required-mark {
    background-color: #00d4f8; }
  div.subject-item div.subject-marks div.aps-points {
    background-color: #00a0f4; }
