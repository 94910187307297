div.message-item {
  padding-top: 1em; }
  div.message-item div.user-pro-pic {
    display: inline-block;
    width: 3em;
    height: 3em;
    border-radius: 1.5em;
    overflow: hidden;
    background-color: #E9EBEE;
    margin-right: 0.5em;
    vertical-align: top; }
    div.message-item div.user-pro-pic img {
      width: 100%; }
    div.message-item div.user-pro-pic.right {
      float: right; }
    div.message-item div.user-pro-pic.left {
      float: left; }
  div.message-item div.message {
    display: inline-block;
    width: 27em;
    padding: 1em;
    background-color: #6DD400;
    border-radius: 3px;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    font-weight: 400; }
    div.message-item div.message.green {
      background-color: #6DD400; }
    div.message-item div.message.blue {
      background-color: #32C5FF; }
  div.message-item p.message-status {
    margin-bottom: 0;
    margin-top: 0.5em;
    padding-right: 1em;
    text-transform: capitalize;
    text-align: right;
    color: #9DA2AB;
    font-family: "Open Sans", sans-serif;
    font-size: 0.845em;
    font-weight: 600; }
    div.message-item p.message-status i {
      color: #9DA2AB;
      margin-right: 0.5em; }
  div.message-item p.left {
    float: left;
    margin-left: 4em; }
  div.message-item p.right {
    float: right;
    margin-right: 4em; }
