div.application-list-titles {
  border-bottom: 1px solid #EBF1F5;
  margin-bottom: 1.5em; }
  div.application-list-titles h5 {
    color: #9DA2AB;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 600; }
  div.application-list-titles div.form-select-wrap {
    margin-bottom: 0.5em; }
