div.user-item {
  position: relative;
  padding: 0.5em;
  border-top: 1px solid #9DA2AB; }
  div.user-item:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2); }
  div.user-item div.pro-pic-wrap, div.user-item h5 {
    display: inline-block; }
  div.user-item div.pro-pic-wrap {
    display: inline-block;
    background-color: #EBF1F5;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    overflow: hidden;
    vertical-align: middle;
    margin-right: 0.5em; }
    div.user-item div.pro-pic-wrap img {
      width: 100%;
      margin-right: 0.5em; }
  div.user-item h5 {
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    font-weight: 600; }
  div.user-item div.messages-cocunt {
    text-align: center; }
    div.user-item div.messages-cocunt i {
      margin-right: 0.5em; }
    div.user-item div.messages-cocunt span {
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 300; }
  div.user-item.active {
    background-color: #2B54D0;
    color: white; }
