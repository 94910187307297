section.applications-container p.no-applications {
  text-transform: capitalize; }

section.applications-container div.dashboards-section {
  margin-top: 2.5em; }
  section.applications-container div.dashboards-section h5 {
    color: #9DA2AB;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2875em;
    font-weight: 600; }
