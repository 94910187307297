div.filter-list-wrap,
div.filter-option-name-wrap,
div.filter-option-grade-wrap,
div.points-input-wrap,
div.filter-option-school-wrap,
div.filter-option-province-wrap,
div.date-input-wrap,
div.filter-option-source-wrap,
div.filter-option-username-wrap {
  display: inline-block;
  height: 2.5em;
  line-height: 2.5em;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: 1.25em;
  border: 1px solid #c2c9d5;
  background-color: #EBF1F5;
  border-radius: 1.25em; }

div.filter-list-wrap select {
  width: 8.5em;
  border: transparent;
  background-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-size: 1.0624em;
  font-weight: 400; }
  div.filter-list-wrap select:focus {
    outline: none; }

div.filter-option-name-wrap,
div.points-input-wrap,
div.filter-option-school-wrap,
div.date-input-wrap,
div.filter-option-province-wrap,
div.filter-option-source-wrap,
div.filter-option-username-wrap,
div.filter-option-grade-wrap {
  width: 20rem; }
  div.filter-option-name-wrap input,
  div.points-input-wrap input,
  div.filter-option-school-wrap input,
  div.date-input-wrap input,
  div.filter-option-province-wrap input,
  div.filter-option-source-wrap input,
  div.filter-option-username-wrap input,
  div.filter-option-grade-wrap input {
    width: 100%;
    height: 2.2em;
    border: transparent;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
    div.filter-option-name-wrap input:focus,
    div.points-input-wrap input:focus,
    div.filter-option-school-wrap input:focus,
    div.date-input-wrap input:focus,
    div.filter-option-province-wrap input:focus,
    div.filter-option-source-wrap input:focus,
    div.filter-option-username-wrap input:focus,
    div.filter-option-grade-wrap input:focus {
      outline: none; }
  div.filter-option-name-wrap ::placeholder,
  div.points-input-wrap ::placeholder,
  div.filter-option-school-wrap ::placeholder,
  div.date-input-wrap ::placeholder,
  div.filter-option-province-wrap ::placeholder,
  div.filter-option-source-wrap ::placeholder,
  div.filter-option-username-wrap ::placeholder,
  div.filter-option-grade-wrap ::placeholder {
    color: rgba(0, 0, 0, 0.125); }
  div.filter-option-name-wrap select,
  div.points-input-wrap select,
  div.filter-option-school-wrap select,
  div.date-input-wrap select,
  div.filter-option-province-wrap select,
  div.filter-option-source-wrap select,
  div.filter-option-username-wrap select,
  div.filter-option-grade-wrap select {
    width: 18rem;
    border: transparent;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
    div.filter-option-name-wrap select:focus,
    div.points-input-wrap select:focus,
    div.filter-option-school-wrap select:focus,
    div.date-input-wrap select:focus,
    div.filter-option-province-wrap select:focus,
    div.filter-option-source-wrap select:focus,
    div.filter-option-username-wrap select:focus,
    div.filter-option-grade-wrap select:focus {
      outline: none; }

div.filter-option-school-wrap {
  position: relative; }
  div.filter-option-school-wrap div.search-list {
    position: absolute;
    left: 0;
    top: 2.5em;
    width: 19.9125em;
    padding-left: 1em;
    padding-right: 1em;
    z-index: 1; }
    div.filter-option-school-wrap div.search-list ul {
      max-height: 20.5em;
      padding: 0.5em;
      overflow-y: scroll;
      list-style-type: none;
      border: 1px solid #EBF1F5;
      background-color: #EBF1F5;
      box-sizing: border-box;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
      div.filter-option-school-wrap div.search-list ul li {
        display: block;
        width: 100%;
        background-color: transparent;
        color: #000000;
        transition: all 0.2s;
        text-decoration: none;
        cursor: pointer; }
        div.filter-option-school-wrap div.search-list ul li:hover {
          background-color: #2B54D0;
          color: #FFFFFF; }
        div.filter-option-school-wrap div.search-list ul li.list-no-result {
          text-align: center;
          text-transform: capitalize;
          color: gray; }

div.filter-option-activity-wrap {
  display: inline-block;
  margin-left: 1.25em; }
  div.filter-option-activity-wrap div.points-input-wrap {
    width: 6em; }

div.filter-option-action-date-wrap,
div.filter-option-created-date-wrap {
  display: inline-block;
  margin-left: 1.25em; }
  div.filter-option-action-date-wrap div.date-input-wrap,
  div.filter-option-created-date-wrap div.date-input-wrap {
    width: 9.5em; }
    div.filter-option-action-date-wrap div.date-input-wrap div.react-date-picker__wrapper,
    div.filter-option-created-date-wrap div.date-input-wrap div.react-date-picker__wrapper {
      border: none; }
      div.filter-option-action-date-wrap div.date-input-wrap div.react-date-picker__wrapper div, div.filter-option-action-date-wrap div.date-input-wrap div.react-date-picker__wrapper button,
      div.filter-option-created-date-wrap div.date-input-wrap div.react-date-picker__wrapper div,
      div.filter-option-created-date-wrap div.date-input-wrap div.react-date-picker__wrapper button {
        padding: unset;
        line-height: 2.5em;
        outline: none; }
    div.filter-option-action-date-wrap div.date-input-wrap div.react-date-picker__calendar,
    div.filter-option-created-date-wrap div.date-input-wrap div.react-date-picker__calendar {
      bottom: 0; }
      div.filter-option-action-date-wrap div.date-input-wrap div.react-date-picker__calendar div.react-calendar,
      div.filter-option-created-date-wrap div.date-input-wrap div.react-date-picker__calendar div.react-calendar {
        border: 1px solid #c2c9d5;
        background-color: #263A78;
        border-radius: 3px; }
        div.filter-option-action-date-wrap div.date-input-wrap div.react-date-picker__calendar div.react-calendar div.react-calendar__month-view__weekdays,
        div.filter-option-created-date-wrap div.date-input-wrap div.react-date-picker__calendar div.react-calendar div.react-calendar__month-view__weekdays {
          color: #FFFFFF; }

.react-date-picker__inputGroup {
  width: 6.25em; }

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #006edc; }

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #006edc; }
