div.applications-list {
  margin-bottom: 3em; }
  div.applications-list h4.title-heading {
    margin-bottom: 0.5em;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2875em;
    font-weight: 600; }
    div.applications-list h4.title-heading a {
      color: #9DA2AB;
      margin-right: 0.5em;
      transition: color 0.125s ease-in; }
      div.applications-list h4.title-heading a:hover {
        color: #3E485A; }
  div.applications-list div.applications h4, div.applications-list div.applications-list h4 {
    color: #9DA2AB;
    padding-left: 0.9em;
    margin-bottom: 3em;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
