div.name-filter {
  margin-top: 1.5em;
  margin-bottom: 2em;
  padding-bottom: 1em;
  border-bottom: 1px solid #EBF1F5; }
  div.name-filter p {
    color: #9DA2AB;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    font-weight: 300; }
  div.name-filter div.auto-complete-search div.search-list {
    position: absolute;
    left: 6.25em;
    top: 4.9em;
    width: 19.9125em;
    padding-left: 1em;
    padding-right: 1em;
    z-index: 1; }
    div.name-filter div.auto-complete-search div.search-list ul {
      max-height: 20.5em;
      padding: .5em;
      overflow-y: scroll;
      list-style-type: none;
      border: 1px solid #EBF1F5;
      background-color: #EBF1F5;
      box-sizing: border-box;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
      div.name-filter div.auto-complete-search div.search-list ul li {
        display: block;
        width: 100%;
        background-color: initial;
        color: #000000;
        transition: all .2s;
        text-decoration: none;
        cursor: pointer; }
        div.name-filter div.auto-complete-search div.search-list ul li:hover {
          background-color: #2B54D0;
          color: #FFFFFF; }
        div.name-filter div.auto-complete-search div.search-list ul li.view-more {
          background-color: #2B54D0;
          color: #FFFFFF;
          font-weight: 700;
          padding: 0.5em;
          text-align: center;
          text-transform: uppercase;
          margin-top: 0.5em; }
  div.name-filter div.auto-complete-search div.search-all {
    display: inline-block;
    margin-left: 1em; }
    div.name-filter div.auto-complete-search div.search-all input[type="checkbox"] {
      position: relative;
      top: -0.1em; }
    div.name-filter div.auto-complete-search div.search-all label {
      text-transform: capitalize;
      color: #9DA2AB;
      margin-left: 0.3em;
      font-family: "Open Sans", sans-serif;
      font-size: 1.0624em;
      font-weight: 400; }
