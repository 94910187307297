div.schools-page ul {
  padding-left: 0;
  list-style-type: none; }
  div.schools-page ul li {
    margin-bottom: 1.5em; }
    div.schools-page ul li.subject-title {
      background-color: #32C5FF;
      padding: 1em;
      margin-bottom: 3em; }
    div.schools-page ul li i.subject-icon {
      display: inline-block;
      font-size: 3em;
      color: #FFFFFF;
      padding: 0.2em; }
    div.schools-page ul li div.mathematics-icon i.subject-icon {
      background-color: #6DD400; }
    div.schools-page ul li div.mathematics-title {
      background-color: #6DD400; }
    div.schools-page ul li div.physics-icon i.subject-icon {
      background-color: #3F8D90; }
    div.schools-page ul li div.physics-title {
      background-color: #3F8D90; }
    div.schools-page ul li div.life-science-icon i.subject-icon {
      background-color: #F7B500; }
    div.schools-page ul li div.life-science-title {
      background-color: #F7B500; }
    div.schools-page ul li div.accounting-icon i.subject-icon {
      background-color: #E02020; }
    div.schools-page ul li div.accounting-title {
      background-color: #E02020; }

div.schools-page div.chart-legend {
  margin-top: 3em; }
  div.schools-page div.chart-legend i {
    font-size: 3em; }
  div.schools-page div.chart-legend div.grade-10-total i {
    color: #32C5FF; }
  div.schools-page div.chart-legend div.grade-11-total i {
    color: #E02020; }
  div.schools-page div.chart-legend div.grade-12-total i {
    color: #F7B500; }
