div.feed-type-container {
  margin-top: 1em; }
  div.feed-type-container h4 {
    color: #3E485A;
    padding-left: 0.5em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    margin-bottom: 1em;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2273em;
    font-weight: 600; }
  div.feed-type-container label {
    display: block;
    margin-bottom: 0.5em;
    text-transform: capitalize;
    color: #9DA2AB;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    font-weight: 600; }
  div.feed-type-container textarea {
    width: 100%;
    height: 8em;
    border-radius: 3px;
    border: 1px solid #ccd1d8;
    outline: none;
    resize: none; }
  div.feed-type-container input[type="text"] {
    width: 100%;
    margin-bottom: 1em;
    border-radius: 3px;
    border: 1px solid #ccd1d8;
    outline: none; }
  div.feed-type-container div.file-upload-wrap {
    display: inline-block;
    width: 9em;
    height: 8em;
    margin-top: 0.5em;
    margin-bottom: 2em;
    margin-right: 1.5em;
    text-align: center; }
    div.feed-type-container div.file-upload-wrap a {
      display: block;
      width: 100%;
      height: 7em;
      line-height: 7em;
      margin-right: 1em;
      color: #3E485A;
      background-color: #EBF1F5;
      border-radius: 3px; }
      div.feed-type-container div.file-upload-wrap a i {
        font-size: 1.5em; }
  div.feed-type-container button {
    background-color: #2B54D0;
    width: 8.525em;
    height: 2em;
    margin-left: 1em;
    margin-top: 0.5em; }
