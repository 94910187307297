div.loading-data {
  text-align: center; }
  div.loading-data button {
    padding: 0.5em 1em;
    background-color: #2B54D0 !important;
    margin-bottom: 0.5em; }
  div.loading-data p {
    color: #3E485A;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    font-weight: 400; }
