section.user-stats-tiles {
  margin-top: 2.8125em; }

section.user-stats-info {
  margin-top: 5em;
  margin-bottom: 3em;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: #EBF1F5;
  min-height: 200px; }

section.loading-tiles {
  margin-top: 5em;
  text-align: center; }
  section.loading-tiles button {
    padding: 0.5em 1em;
    background-color: #2B54D0;
    margin-bottom: 0.5em; }
  section.loading-tiles p {
    color: #3E485A;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    font-weight: 400; }
