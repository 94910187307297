ul.main-login {
  display: block;
  padding-left: 0;
  margin-bottom: 10em;
  padding: 4em 5.5em;
  list-style-type: none;
  background-color: #2B54D0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  ul.main-login li {
    display: inherit;
    margin-bottom: 2.5em; }
    ul.main-login li label {
      display: block;
      margin-bottom: 0.45em;
      text-transform: capitalize;
      color: #FFFFFF;
      font-family: "Open Sans", sans-serif;
      font-size: 1.2875em;
      font-weight: 600; }
    ul.main-login li input {
      width: 100%;
      height: 2.2em;
      padding-left: 1em;
      padding-right: 1em;
      background-color: transparent;
      border: 1px solid #E9EBEE;
      border-radius: 1.1em;
      color: #E9EBEE;
      box-sizing: border-box;
      font-family: "Open Sans", sans-serif;
      font-size: 1.2875em;
      font-weight: 400; }
      ul.main-login li input:focus {
        outline: none; }
    ul.main-login li ::placeholder {
      color: #E9EBEE; }
    ul.main-login li.login-error {
      margin-bottom: 0; }
      ul.main-login li.login-error p {
        margin-left: 1em;
        color: #F44336;
        font-style: italic;
        text-transform: capitalize; }
    ul.main-login li:last-child {
      margin-bottom: 0;
      text-align: center; }
      ul.main-login li:last-child button {
        width: 8.525em;
        height: 2em;
        border-radius: 1em;
        background-color: #263A78;
        transform: scale(1);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
        ul.main-login li:last-child button:hover {
          background-color: #FFFFFF;
          color: #263A78;
          transform: scale(1.03);
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }
        ul.main-login li:last-child button:focus {
          outline: none; }
        ul.main-login li:last-child button.loginLoading {
          background-color: #263A78;
          color: #FFFFFF;
          box-shadow: unset;
          transform: unset;
          cursor: default; }
