section.feeds-section {
  margin-bottom: 5em; }
  section.feeds-section h4.feeds-section-heading {
    color: #3E485A;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    margin-bottom: 1.5em;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2273em;
    font-weight: 600; }
  section.feeds-section div.feeds-container {
    max-height: 50em;
    overflow-y: scroll;
    background-color: #EBF1F5; }
  section.feeds-section div.new-feed div.feeds-filter {
    border-bottom: 1px solid #ccd1d8;
    padding-bottom: 1em; }
