div.user-access-card {
  padding: 0.2em 0.5em;
  background-color: #EBF1F5;
  border: 1px solid #c2c9d5;
  border-radius: 3px;
  margin-right: 1em;
  display: inline-block; }
  div.user-access-card p {
    margin-bottom: 0;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
  div.user-access-card button.del-access-right {
    margin-left: 0.5em;
    color: #f77066; }
