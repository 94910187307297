div.ap-item {
  margin-bottom: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: #FFFFFF; }
  div.ap-item a {
    margin-right: 1em;
    font-size: 1.3758em; }
    div.ap-item a i {
      color: #4A4A4A; }
  div.ap-item p.ap-name {
    display: inline-block;
    margin-bottom: 0;
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2875em;
    font-weight: 400; }
  div.ap-item div.institutions {
    white-space: nowrap;
    overflow-x: scroll; }
