div.admin-tools-container {
  position: relative; }
  div.admin-tools-container a.admin-tools-toggle {
    font-size: 1.7em;
    color: #EBF1F5;
    transition: color 0.25s ease-in; }
  div.admin-tools-container a.active {
    color: #263A78 !important; }
  div.admin-tools-container div.admin-tools {
    position: absolute;
    top: 4.1em;
    left: -4.5em;
    width: 28.5em;
    padding-top: 1em;
    padding-left: 1em;
    background-color: #FFFFFF;
    z-index: 3;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    div.admin-tools-container div.admin-tools div.admin-tool-title {
      position: relative; }
      div.admin-tools-container div.admin-tools div.admin-tool-title h3 {
        display: inline-block;
        margin-bottom: 1em;
        padding-left: 0.2em;
        font-family: "Open Sans", sans-serif;
        font-size: 1.2875em;
        font-weight: 600; }
      div.admin-tools-container div.admin-tools div.admin-tool-title a {
        display: inline-block;
        position: relative;
        right: -12.3em;
        font-size: 1.4em;
        color: #9DA2AB;
        transition: color 0.25s ease; }
        div.admin-tools-container div.admin-tools div.admin-tool-title a:hover {
          color: #3E485A; }
    div.admin-tools-container div.admin-tools div.admin-tool-wrap {
      display: inline-block;
      margin-right: 1em; }
      div.admin-tools-container div.admin-tools div.admin-tool-wrap a.admin-tool {
        padding-top: 1.7em;
        margin-bottom: 1em;
        width: 8.075em;
        height: 8.075em; }
        div.admin-tools-container div.admin-tools div.admin-tool-wrap a.admin-tool i {
          font-size: 1.2125em; }
        div.admin-tools-container div.admin-tools div.admin-tool-wrap a.admin-tool span {
          font-size: 0.8875em; }
