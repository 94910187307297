div.module-table-titles {
  border-bottom: 1px solid #ccd1d8; }
  div.module-table-titles h6 {
    text-transform: capitalize;
    padding-left: 0.7375em;
    color: #9DA2AB;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 600; }
    div.module-table-titles h6.right-aligned {
      text-align: right;
      margin-right: 0.5em; }
