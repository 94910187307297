div.page-tool-heading a {
  color: #000000;
  text-decoration: none; }
  div.page-tool-heading a i {
    margin-right: 0.5em; }

div.application-status-btn button {
  padding-left: 1em;
  padding-right: 1em;
  background-color: #2B54D0; }
