div.match-criteria {
  display: inline-block;
  margin-right: 0.5em;
  padding: 0.3em;
  border-radius: 3px;
  color: #FFFFFF;
  text-transform: capitalize;
  transition: all 0.0125s ease-in;
  font-family: "Open Sans", sans-serif;
  font-size: 0.845em;
  font-weight: 600; }
  div.match-criteria:hover {
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

div.red {
  background-color: #E02020; }

div.orange {
  background-color: #F7B500; }

div.green {
  background-color: #6DD400; }
