a.round-button {
  width: 2.1875em;
  height: 2.1875em;
  display: inline-block;
  text-align: center;
  line-height: 2.1875em;
  border-radius: 50%;
  color: white; }
  a.round-button.add-filter {
    background-color: #7ED321;
    margin-right: 0.625em; }
  a.round-button.remove-filter {
    width: 1.5625em;
    height: 1.5625em;
    line-height: 1.5625em;
    margin-right: 0.625em;
    background-color: #F44336; }
  a.round-button.search {
    float: right;
    background-color: #263A78; }
  a.round-button:hover {
    color: white; }

label.filter-label {
  position: relative;
  display: inline-block;
  padding-left: 0.7375em;
  color: #9DA2AB;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  font-size: 1.0624em;
  font-weight: 600; }

div.extra-filter {
  margin-top: 0.9em;
  margin-left: 3.2em; }
  div.extra-filter label.line-connector {
    position: relative;
    top: -1em;
    display: inline-block;
    width: 2.5em;
    height: 2.5em; }
    div.extra-filter label.line-connector:before, div.extra-filter label.line-connector:after {
      position: absolute;
      display: block;
      content: "";
      background-color: #c2c9d5; }
    div.extra-filter label.line-connector:before {
      top: -0.9em;
      left: 0;
      width: 1px;
      height: 4.6em; }
    div.extra-filter label.line-connector:after {
      bottom: -1.2em;
      left: 0;
      width: 2.5em;
      height: 1px; }
