div.chart-sort-filter {
  float: right; }
  div.chart-sort-filter label {
    text-transform: capitalize;
    margin-right: 1em;
    margin-left: 0.5em;
    color: #3E485A;
    font-family: "Open Sans", sans-serif;
    font-size: 0.845em;
    font-weight: 400; }
