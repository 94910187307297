div.report-print {
  position: fixed;
  top: 3.825em;
  left: 16em;
  width: 62em;
  height: 100%;
  padding: 2em;
  background-color: #FFFFFF;
  z-index: 101;
  overflow: scroll;
  padding-bottom: 10em; }
  div.report-print button {
    background-color: #2B54D0;
    float: right;
    margin-top: 3em;
    padding-left: 1em;
    padding-right: 1em; }
    div.report-print button i {
      margin-right: 0.5em; }

@media print {
  div.report-print {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: initial;
    padding: unset !important; }
    div.report-print button {
      display: none; } }
