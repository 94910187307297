section.ap-dashboard div.group div.table-headings {
  border-bottom: 1px solid #d4d6da;
  margin-bottom: 1.5em; }
  section.ap-dashboard div.group div.table-headings h6 {
    text-transform: capitalize; }

section.ap-dashboard div.group div.ap-list-wrap {
  overflow-x: scroll;
  white-space: nowrap;
  height: 31em; }
