section#main-content {
  margin-left: 19.5em;
  margin-top: 5.625em;
  padding-top: 2.5em;
  padding-left: 4.5em;
  padding-right: 4.5em; }

@media print {
  section#main-content {
    margin-left: unset !important;
    padding-top: unset !important;
    margin-top: unset !important;
    padding-left: unset !important;
    padding-right: unset !important; } }
