div.chart-info h4 {
  color: #3E485A;
  padding-left: 0.5em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-bottom: 1.5em;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2273em;
  font-weight: 600; }

div.chart-info div.stats-info h4 {
  margin-bottom: 0.5em;
  padding: 0.5em 0.8em;
  color: #FFFFFF;
  background-color: #2B54D0;
  font-family: "Open Sans", sans-serif;
  font-size: 0.95em;
  font-weight: 400; }
  div.chart-info div.stats-info h4 i {
    padding-right: 0.5em; }
  div.chart-info div.stats-info h4.top-five-qualifications-m-heading {
    background-color: #EEC751; }
  div.chart-info div.stats-info h4.top-five-careers-heading {
    background-color: #789055; }

div.chart-info div.stats-info ul {
  position: relative;
  padding-left: 0;
  list-style-type: none; }
  div.chart-info div.stats-info ul li {
    position: relative;
    width: 100%;
    margin-bottom: 0.3em; }
    div.chart-info div.stats-info ul li span {
      position: relative;
      display: inline-block;
      vertical-align: top;
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 400; }
    div.chart-info div.stats-info ul li h6 {
      color: #999999;
      font-family: "Open Sans", sans-serif;
      font-size: 0.845em;
      font-weight: 600; }
