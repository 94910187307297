div.student-bursaries {
  margin-top: -0.5em;
  padding-top: 1.25em;
  border-left: 1px solid #EBF1F5;
  border-right: 1px solid #EBF1F5;
  border-bottom: 1px solid #EBF1F5; }
  div.student-bursaries h5.bursaries-title {
    margin-bottom: 2em; }
  div.student-bursaries div.application-item p {
    display: inline-block; }
  div.student-bursaries div.application-item a.delete-row {
    text-decoration: none; }
    div.student-bursaries div.application-item a.delete-row i {
      color: #F44336; }
