a.admin-tool {
  display: inline-block;
  padding-top: 3em;
  margin-bottom: 3em;
  width: 13.875em;
  height: 13.875em;
  text-align: center;
  background-color: #2B54D0;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: scale(1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  a.admin-tool:hover {
    text-decoration: none;
    color: #FFFFFF;
    transform: scale(1.08);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }
    a.admin-tool:hover i {
      color: #263A78;
      background-color: #EBF1F5; }
  a.admin-tool i {
    display: inline-block;
    margin-bottom: 0.3625em;
    width: 1.9625em;
    height: 1.9625em;
    line-height: 1.9625em;
    font-size: 2.2125em;
    color: #EBF1F5;
    background-color: #263A78;
    border-radius: 1.1em;
    transition: all 0.2s ease-in; }
  a.admin-tool span {
    display: block;
    text-transform: capitalize;
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2875em;
    font-weight: 600; }
