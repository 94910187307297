div.info-row {
  background-color: #EBF1F5;
  margin-bottom: 1em;
  padding-top: 1.0625em;
  padding-bottom: 1.0625em;
  border-radius: 3px; }
  div.info-row div.info-row-item a {
    display: inline-block;
    color: #CDCFD1;
    font-size: 1.3758em; }
    div.info-row div.info-row-item a.active {
      color: #2B54D0; }
  div.info-row div.info-row-item input {
    width: 100%;
    padding: 0.3em;
    border: none;
    background-color: transparent;
    outline: none;
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 400; }
  div.info-row div.info-row-item select {
    border: transparent;
    background-color: #EBF1F5; }
  div.info-row div.info-row-item ::placeholder {
    color: #9DA2AB; }
  div.info-row div.info-row-item:first-child {
    border-right: none; }
  div.info-row div.info-row-item:last-child {
    text-align: right; }
