section.applicant-info {
  position: fixed;
  top: 5em;
  left: 0;
  width: 60em;
  height: 38em;
  max-height: 38em;
  overflow-y: scroll;
  margin-left: 50%;
  padding-bottom: 1.5em;
  z-index: 101;
  background-color: #EBF1F5;
  transform: translateX(-50%); }
  section.applicant-info div.loading-info {
    margin-top: 10em; }
  section.applicant-info div.header {
    padding-top: 1.5em; }
    section.applicant-info div.header img {
      margin-top: 3em;
      border: 1px solid #E9EBEE;
      border-radius: 3px;
      width: 14em;
      background-color: #EBF1F5; }
    section.applicant-info div.header h2 {
      margin-bottom: 0.5em;
      font-family: "Open Sans", sans-serif;
      font-size: 1.5875em;
      font-weight: 600; }
    section.applicant-info div.header p {
      display: inline-block;
      margin-right: 1em;
      margin-bottom: 0.5; }
      section.applicant-info div.header p span {
        color: #ccd1d8;
        text-transform: capitalize;
        margin-right: 0.5em;
        font-family: "Open Sans", sans-serif;
        font-size: 0.95em;
        font-weight: 600; }
    section.applicant-info div.header:before {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 10em;
      content: "";
      background: #2B54D0;
      background: linear-gradient(to right, #298f65 0, #4788de 51%, #a418dd 100%); }
  section.applicant-info div.title {
    margin-top: 2em; }
    section.applicant-info div.title h5 {
      margin-top: 1em;
      text-transform: capitalize;
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 600; }
  section.applicant-info div.applicant-details,
  section.applicant-info div.applicant-parent-details,
  section.applicant-info div.applicant-subjects {
    margin-top: 0.5em;
    margin-left: 0.3em;
    margin-right: 0.3em;
    padding: 1em;
    background-color: #FFFFFF; }
  section.applicant-info button.save {
    background-color: #2B54D0;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 1em;
    float: right; }
    section.applicant-info button.save i {
      margin-right: 0.5em; }
