section.qualification-match-info {
  position: fixed;
  top: 5em;
  left: 0;
  width: 60em;
  height: 38em;
  max-height: 38em;
  overflow-y: scroll;
  margin-left: 50%;
  padding-bottom: 1.5em;
  z-index: 101;
  background-color: #EBF1F5;
  transform: translateX(-50%); }
  section.qualification-match-info div.header {
    padding-top: 1.5em; }
    section.qualification-match-info div.header img {
      margin-top: 3em;
      border: 1px solid #E9EBEE;
      border-radius: 3px;
      width: 14em; }
    section.qualification-match-info div.header h2 {
      font-family: "Open Sans", sans-serif;
      font-size: 1.5875em;
      font-weight: 600; }
    section.qualification-match-info div.header:before {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 10em;
      content: "";
      background: #2B54D0;
      background: linear-gradient(to right, #298f65 0, #4788de 51%, #a418dd 100%); }
  section.qualification-match-info div.rate-info {
    margin-top: 1em; }
    section.qualification-match-info div.rate-info h3 {
      font-family: "Open Sans", sans-serif;
      font-size: 1.2875em;
      font-weight: 600; }
    section.qualification-match-info div.rate-info p {
      padding: 0.5em;
      color: #FFFFFF;
      margin-bottom: 0;
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 400; }
    section.qualification-match-info div.rate-info p.green {
      background-color: #4CAF50; }
    section.qualification-match-info div.rate-info p.red {
      background-color: #F44336; }
  section.qualification-match-info div.description {
    margin-top: 2em; }
    section.qualification-match-info div.description h5 {
      text-transform: capitalize;
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 600; }
    section.qualification-match-info div.description p {
      margin-bottom: 0;
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 400; }
  section.qualification-match-info div.match-required-info {
    margin-top: 1.5em; }
    section.qualification-match-info div.match-required-info div {
      text-align: center; }
      section.qualification-match-info div.match-required-info div img {
        height: 1.5em;
        margin-bottom: 0.5em; }
      section.qualification-match-info div.match-required-info div h5 {
        font-family: "Open Sans", sans-serif;
        font-size: 0.95em;
        font-weight: 600; }
      section.qualification-match-info div.match-required-info div p {
        margin-bottom: 0;
        font-family: "Open Sans", sans-serif;
        font-size: 0.95em;
        font-weight: 400; }
  section.qualification-match-info div.match-points {
    margin-top: 2em;
    border-top: 1px solid #ccd1d8;
    border-bottom: 1px solid #ccd1d8;
    padding-top: 1em;
    padding-bottom: 1em; }
    section.qualification-match-info div.match-points div {
      text-align: center; }
      section.qualification-match-info div.match-points div p {
        margin-bottom: 0.5em;
        text-transform: uppercase;
        color: #2B54D0;
        font-family: "Open Sans", sans-serif;
        font-size: 1.0624em;
        font-weight: 400; }
        section.qualification-match-info div.match-points div p.red {
          color: #F44336; }
  section.qualification-match-info div.subjects {
    margin-top: 2em; }
    section.qualification-match-info div.subjects h5 {
      margin-top: 1em;
      text-transform: uppercase;
      font-family: "Open Sans", sans-serif;
      font-size: 0.95em;
      font-weight: 600; }
