div.subject-input {
  margin-bottom: 1em; }

div.auto-complete-search {
  position: relative; }
  div.auto-complete-search div.form-text-wrap input {
    width: 85%; }
  div.auto-complete-search div.search-list {
    position: absolute;
    left: 0;
    top: 4.6em;
    width: 18.5125em;
    padding-left: 1em;
    padding-right: 1em;
    z-index: 1; }
    div.auto-complete-search div.search-list ul {
      max-height: 20.5em;
      padding: 0.5em;
      overflow-y: scroll;
      list-style-type: none;
      border: 1px solid #EBF1F5;
      background-color: #EBF1F5;
      box-sizing: border-box;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
      div.auto-complete-search div.search-list ul li {
        display: block;
        width: 100%;
        background-color: transparent;
        color: #000000;
        transition: all 0.2s;
        text-decoration: none;
        cursor: pointer; }
        div.auto-complete-search div.search-list ul li:hover {
          background-color: #2B54D0;
          color: #FFFFFF; }
        div.auto-complete-search div.search-list ul li.list-no-result {
          text-align: center;
          text-transform: capitalize;
          color: gray; }
