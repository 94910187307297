div.page-tool-heading {
  margin-bottom: 1.7375em; }
  div.page-tool-heading h3 {
    margin-bottom: 0.5625em;
    margin-bottom: 0;
    padding-left: 0.5em;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5875em;
    font-weight: 600; }
  div.page-tool-heading p {
    margin-bottom: 0;
    text-transform: capitalize;
    text-align: right;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 300; }

section.modules-table-heading {
  margin-bottom: 1.25em; }

section.modules-table-rows {
  padding-left: 0.5675em; }
  section.modules-table-rows div.button-row {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: right; }
    section.modules-table-rows div.button-row button {
      background-color: #2B54D0;
      width: 8.525em;
      height: 2em; }
      section.modules-table-rows div.button-row button i {
        margin-right: 0.3em; }

@media print {
  div.page-tool-heading {
    display: none !important; } }
