div.text-area-wrap textarea {
  border: 1px solid #939dab;
  border-radius: 3px;
  color: #3E485A;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5875em;
  font-weight: 700; }

div.text-area-wrap a {
  display: none;
  background-color: #263A78;
  float: right; }
  div.text-area-wrap a i {
    color: #FFFFFF; }

@media print {
  div.text-area-wrap textarea {
    border: 1px solid transparent; } }
