div.image-area-wrap div.image-wrap {
  text-align: center;
  border: 1px solid #939dab;
  border-radius: 3px;
  margin-bottom: 1em;
  padding: 1em; }

div.image-area-wrap div.area-controls span, div.image-area-wrap div.area-controls div {
  display: inline-block; }

div.image-area-wrap div.area-controls div {
  float: right; }
  div.image-area-wrap div.area-controls div a {
    background-color: #263A78;
    margin-right: 1em; }

@media print {
  div.image-area-wrap div.image-wrap {
    border: 1px solid transparent; }
  div.image-area-wrap div.area-controls {
    display: none; } }
