a.user-profile-link {
  display: inline-block;
  width: 1.525em;
  height: 1.525em;
  text-align: center;
  border-radius: 0.9em;
  background-color: #263A78;
  font-size: 1.625em;
  margin-right: 0.3em; }

a.user-profile-toggle, a.user-profile-link {
  color: #EBF1F5; }
  a.user-profile-toggle:hover, a.user-profile-link:hover {
    color: #EBF1F5; }
