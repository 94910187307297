html,
body,
div#root {
  min-height: 100%; }

body {
  position: relative;
  height: 100%; }
  body:before {
    position: absolute;
    display: block;
    width: 53%;
    min-height: 100%;
    top: 0;
    left: 0;
    content: '';
    background-color: #EBF1F5; }

@media print {
  body {
    height: auto !important; } }
