div.usefulness-page div.page-title {
  margin-bottom: 1em; }

div.usefulness-page div.usefulness-block {
  background-color: #F7B500;
  padding-top: 3em;
  padding-bottom: 1em;
  margin-top: 6em;
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.4); }
  div.usefulness-page div.usefulness-block:before {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 6em;
    height: 6em;
    background-color: #000000;
    border-radius: 50%;
    text-align: center;
    line-height: 6em;
    color: #FFFFFF; }

div.usefulness-page div.usefulness01 {
  z-index: 5; }
  div.usefulness-page div.usefulness01:before {
    content: "A";
    z-index: 4;
    top: -5em; }

div.usefulness-page div.usefulness02 {
  z-index: 4;
  padding-top: 10em; }
  div.usefulness-page div.usefulness02:before {
    content: "B";
    background-color: #E02020;
    top: 2.5em;
    left: -1.5em; }

div.usefulness-page div.usefulness03 {
  z-index: 3; }
  div.usefulness-page div.usefulness03:before {
    content: "C";
    background-color: #32C5FF;
    top: -3.5em;
    left: -1.5em; }

div.usefulness-page div.usefulness04 {
  z-index: 2;
  padding-top: 10em; }
  div.usefulness-page div.usefulness04:before {
    content: "D";
    background-color: #6DD400;
    top: 2.5em;
    left: -1.5em; }

div.usefulness-page div.usefulness05 {
  z-index: 1; }
  div.usefulness-page div.usefulness05:before {
    content: "E";
    background-color: #3F8D90;
    top: -3.5em;
    left: -1.5em; }
