div.user-list {
  height: 40em;
  overflow-y: scroll;
  background-color: rgba(216, 216, 216, 0.5); }
  div.user-list h6 {
    text-transform: capitalize;
    margin-bottom: 1em;
    margin-left: 0.7em;
    padding-top: 1em;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    font-weight: 600; }
  div.user-list div.no-users {
    text-align: center; }
    div.user-list div.no-users i {
      padding: 1em;
      border: 1px solid #000000;
      margin-bottom: 0.5em;
      border-radius: 50%; }
    div.user-list div.no-users p {
      margin-bottom: 0;
      font-family: "Open Sans", sans-serif;
      font-size: 1.0624em;
      font-weight: 600; }

div.ac-list {
  margin-right: 0.5em; }
