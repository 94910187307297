div.feeds-filter label.filter-label {
  position: relative;
  display: inline-block;
  margin-left: 0;
  color: #9DA2AB;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  font-size: 1.0624em;
  font-weight: 600; }

div.feeds-filter div.filter-list-wrap {
  display: inline-block;
  height: 2.5em;
  line-height: 2.5em;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: 1.25em;
  border: 1px solid #c2c9d5;
  background-color: #EBF1F5;
  border-radius: 1.25em; }
  div.feeds-filter div.filter-list-wrap select {
    width: 8.5em;
    border: transparent;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95em;
    font-weight: 600; }
    div.feeds-filter div.filter-list-wrap select:focus {
      outline: none; }
