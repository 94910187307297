section#toolbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 19.5em;
  padding-left: 6.25em;
  padding-top: 5.625em;
  background-color: #EBF1F5; }
  section#toolbar nav {
    width: 9.5em;
    box-sizing: border-box; }
    section#toolbar nav#toolbar-nav {
      padding-top: 2.5em;
      height: 27em; }
    section#toolbar nav#toolbar-nav-settings {
      padding-top: 0.95em;
      border-top: 1px solid #ccd1d8; }

@media print {
  section#toolbar {
    display: none !important; } }
