div.contents-page div.page-heading {
  margin-bottom: 5em; }

div.contents-page img {
  margin-left: 2em;
  margin-top: 3em; }

div.contents-page ul {
  list-style-type: none; }
  div.contents-page ul li {
    margin-top: 3em; }
    div.contents-page ul li.title {
      background-color: #6DD400; }
    div.contents-page ul li.contents1 {
      background-color: #6DD400; }
    div.contents-page ul li.contents2 {
      background-color: #32C5FF; }
    div.contents-page ul li.contents3 {
      background-color: #3F8D90; }
    div.contents-page ul li.contents4 {
      background-color: #F7B500; }
    div.contents-page ul li.contents5 {
      background-color: #E02020; }

@media print {
  div.contents-page {
    height: 29.7cm; } }
