div.page-tool-heading h3 {
  padding-left: 0; }

section.view-users-filter {
  border-bottom: 1px solid #ccd1d8;
  padding-bottom: 1em; }

section.users-count {
  margin-top: 2.8125em; }
  section.users-count p {
    margin-bottom: 0;
    text-transform: capitalize;
    text-align: right;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 300; }
    section.users-count p a {
      margin-left: 0.625em;
      background-color: #263A78; }

section.users-table-titles {
  margin-top: 2.8125em;
  margin-bottom: 1.25em;
  border-bottom: 1px solid #ccd1d8; }
  section.users-table-titles h6 {
    text-transform: capitalize;
    color: #9DA2AB;
    font-family: "Open Sans", sans-serif;
    font-size: 1.0624em;
    font-weight: 600; }
    section.users-table-titles h6.right-aligned {
      text-align: right;
      margin-right: 0.5em; }

div.view-more {
  margin-bottom: 2em; }
  div.view-more button.view-more {
    background-color: #2B54D0;
    padding-left: 1em;
    padding-right: 1em; }
