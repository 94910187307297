div.user-info {
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  margin-bottom: 1em;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; }
  div.user-info div.page-tool-heading h3 {
    font-size: 1.2875em; }
  div.user-info section.user-profile-details {
    margin-bottom: 2em; }
    div.user-info section.user-profile-details h6 {
      text-transform: capitalize;
      padding-left: .7375em;
      padding-bottom: 0.3em;
      color: #9DA2AB;
      font-family: Open Sans,sans-serif;
      font-size: 1.0624em;
      font-weight: 600;
      border-bottom: 1px solid #ccd1d8; }
  div.user-info button.save {
    background-color: #2B54D0;
    padding-left: 1em;
    padding-right: 1em;
    float: right; }
    div.user-info button.save i {
      margin-right: 0.5em; }
  div.user-info div.filter-list-wrap {
    margin-left: 0; }
